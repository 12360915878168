import axios from "axios";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
export default function AdminForgotPwd() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [code, setcode] = useState("");
  const [password, setpassword] = useState("");
  const [ShowVerificationComponent, setShowVerificationComponent] =
    useState(false);
  const [ShowNewPwdComponent, setShowNewPwdComponent] = useState(false);
  const forgotpwdFn = async () => {
    try {
      const config = {
        url: "/admin/subadminforgotpwd",
        method: "put",
        baseURL: "https://softwarelinks.in/api",
        headers: { "content-type": "application/json" },
        data: {
          email: email,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setShowVerificationComponent(true);
        return alert(res.data.success);
      }
    } catch (error) {
      console.log(error);
      return alert(error.response.data.error);
    }
  };

  const verifyForgotPwdCodeFn = async () => {
    try {
      const config = {
        url: "/admin/verifyForgotPwdCode",
        method: "put",
        baseURL: "https://softwarelinks.in/api",
        headers: { "content-type": "application/json" },
        data: {
          email: email,
          code: code,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setShowVerificationComponent(false);
        setShowNewPwdComponent(true);
        return alert(res.data.success);
      }
    } catch (error) {
      console.log(error);
      return alert(error.response.data.error);
    }
  };

  const updateFn = async () => {
    try {
      const config = {
        url: "/admin/updatePasswordforgotPwd",
        method: "put",
        baseURL: "https://softwarelinks.in/api",
        headers: { "content-type": "application/json" },
        data: {
          email: email,
          password: password,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setShowVerificationComponent(false);
        setShowNewPwdComponent(true);
        alert(res.data.success);
        navigate("/admin");
      }
    } catch (error) {
      console.log(error);
      return alert(error.response.data.error);
    }
  };
  return (
    <div className="adminForgotPwdPage">
      {!ShowVerificationComponent && !ShowNewPwdComponent ? (
        <div className="adminforgotPwd-main-container">
          <h2 className="adminforgotPwd-heading">Forgot password</h2>
          <div>
            <div className="adminforgotPwd-subheading">Email</div>
            <div>
              <input
                className="adminforgotPwd-input"
                placeholder="Enter Email ID"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <Button
            variant="primary"
            className="adminforgotPwd-btn"
            onClick={forgotpwdFn}
          >
            Send Verification Code
          </Button>
        </div>
      ) : ShowVerificationComponent ? (
        <div className="adminforgotPwd-main-container">
          <h2 className="adminforgotPwd-heading">Authentication Code</h2>
          <div>
            <input
              className="adminforgotPwd-input"
              placeholder="Enter authentication code"
              value={code}
              onChange={(e) => setcode(e.target.value)}
            />
          </div>
          <Button
            variant="primary"
            className="adminforgotPwd-btn"
            onClick={verifyForgotPwdCodeFn}
          >
            Verify Code
          </Button>
        </div>
      ) : ShowNewPwdComponent ? (
        <div className="adminforgotPwd-main-container">
          <h2 className="adminforgotPwd-heading">Update Password</h2>
          <div>
            <div className="adminforgotPwd-subheading">New Password</div>
            <input
              className="adminforgotPwd-input"
              placeholder="Enter New Password"
              value={password}
              onChange={(e) => setpassword(e.target.value)}
            />
          </div>
          <Button
            variant="primary"
            className="adminforgotPwd-btn"
            onClick={updateFn}
          >
            Set New Password
          </Button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
