import React, { useEffect, useState } from "react";
import { Button, Container, InputGroup, Table } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { CkEditorComponent } from "../CkEditor/CkEditorComponent";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { useLocation } from "react-router-dom";

export const EditProduct = () => {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const location = useLocation();
  const { item } = location.state;
  console.log("item", item);

  const Vendor = JSON.parse(sessionStorage.getItem("VendorDetails"));

  const NormalValuesClose = () => setShow(false);
  const NormalValuesShow = () => setShow(true);
  const MandatoryValueClose = () => setShow1(false);
  const MandatoryValueShow = () => setShow1(true);

  const formdata = new FormData();
  const [productType, setproductType] = useState();
  const [productName, setproductName] = useState();
  const [HSN, setHSN] = useState("");
  const [Batch, setBatch] = useState("");
  const [No_of_Strips, setNo_of_Strips] = useState(0);
  const [No_Tablets_strips, setNo_Tablets_strips] = useState(0);
  const [Scheme, setScheme] = useState("");
  const [productPrice, setproductPrice] = useState();
  const [MRP, setMRP] = useState(0);
  const [CGST, setCGST] = useState(0);
  const [SGST, setSGST] = useState(0);

  const [ProductCategory, setProductCategory] = useState();
  const [ProductSubCategory, setProductSubCategory] = useState();

  const [manufacturingDate, setmanufacturingDate] = useState();
  const [expiryDate, setexpiryDate] = useState();
  const [discount, setdiscount] = useState();
  const [productSize, setproductSize] = useState();
  const [packSize, setpackSize] = useState();
  const [colour, setcolour] = useState();
  const [flavour, setflavour] = useState();
  const [fragrance, setfragrance] = useState();
  const [variant, setvariant] = useState();

  const [description, setdescription] = useState();
  const [brand, setbrand] = useState();
  const [countryOfOrigin, setcountryOfOrigin] = useState();
  const [manufacturercompanyname, setmanufacturercompanyname] = useState();
  const [manufactureraddress, setmanufactureraddress] = useState();
  const [stock, setstock] = useState();
  // const [minstock, setminstock] = useState();
  const [currencyFormat, setcurrencyFormat] = useState();
  const [productImgs, setproductImgs] = useState([]);
  const [safetyDoc, setsafetyDoc] = useState("");
  const [GstDocument, setGstDocument] = useState("");

  const EditProduct = async (e) => {
    e.preventDefault();
    if (
      !No_of_Strips &&
      !No_Tablets_strips &&
      !HSN &&
      !Batch &&
      !MRP &&
      !CGST &&
      !SGST &&
      !productType &&
      !productName &&
      !productPrice &&
      !ProductCategory &&
      !ProductSubCategory &&
      !manufacturingDate &&
      !expiryDate &&
      !discount &&
      !productSize &&
      !packSize &&
      !colour &&
      !flavour &&
      !fragrance &&
      !variant &&
      !description &&
      !brand &&
      !countryOfOrigin &&
      !manufacturercompanyname &&
      !manufactureraddress &&
      !stock &&
      !currencyFormat &&
      productImgs?.length === 0 &&
      !safetyDoc &&
      !GstDocument
    ) {
      alert("There is no changes to Upfdate");
    } else {
      let obj1 = {
        id: item?._id,
        HSN: HSN ? HSN : item?.HSN,
        Batch: Batch ? Batch : item?.Batch,
        No_of_Strips: No_of_Strips ? No_of_Strips : item?.No_of_Strips,
        No_Tablets_strips: No_Tablets_strips
          ? No_Tablets_strips
          : item?.No_Tablets_strips,
        Scheme: Scheme ? Scheme : item?.Scheme,
        MRP: MRP ? MRP : item?.MRP,
        CGST: CGST ? CGST : item?.CGST,
        SGST: SGST ? SGST : item?.SGST,
        productName: productName ? productName : item?.productName,
        productPrice: productPrice ? productPrice : item?.productPrice,
        productType: productType ? productType : item?.productType,
        categoryid: ProductCategory ? ProductCategory : item?.categoryid?._id,
        subcategoryid: ProductSubCategory
          ? ProductSubCategory
          : item?.subcategoryid?._id,
        // Category: ProductCategory?.categoryName,
        // Subcategory: ProductSubCategory?.subcategoryName,
        manufacturingDate: manufacturingDate
          ? manufacturingDate
          : item?.manufacturingDate,
        expiryDate: expiryDate ? expiryDate : item?.expiryDate,
        discount: discount ? discount : item?.discount,
        productSize: productSize ? productSize : item?.productSize,
        packSize: packSize ? packSize : item?.packSize,
        colour: colour ? colour : item?.colour,
        flavour: flavour ? flavour : item?.flavour,
        fragrance: fragrance ? fragrance : item?.fragrance,
        variant: variant ? variant : item?.variant,
        description: description ? description : item?.description,
        brand: brand ? brand : item?.brand,
        countryOfOrigin: countryOfOrigin
          ? countryOfOrigin
          : item?.countryOfOrigin,
        manufacturercompanyname: manufacturercompanyname
          ? manufacturercompanyname
          : item?.manufacturercompanyname,
        manufactureraddress: manufactureraddress
          ? manufactureraddress
          : item?.manufactureraddress,
        stock: stock ? stock : item?.stock,
        // minstock: minstock,
        currencyFormat: currencyFormat ? currencyFormat : item?.currencyFormat,
        productImgs: productImgs ? productImgs : item?.productImgs[0],
        safetyDoc: safetyDoc ? safetyDoc : item?.safetyDoc,
        GstDocument: GstDocument ? GstDocument : item?.GstDocument,
      };

      try {
        const config = {
          url: "/vendor/EditProduct",
          method: "post",
          baseURL: "https://softwarelinks.in/api",
          headers: { "content-type": "multipart/form-data" },
          data: obj1,
        };
        let res = await axios(config);
        if (res.status === 200) {
          console.log(res.data);
          console.log(res.data.success);
          alert("Product Updated Successfully");
          window.location.assign("/VendorAddProducts");
        }
      } catch (error) {
        console.log(error.response);
        if (error.response) {
          alert(error.response.data.error);
        }
      }
    }
  };

  const [categoryList, setcategoryList] = useState([]);
  const getAllCategory = async () => {
    try {
      const res = await axios.get(
        "https://softwarelinks.in/api/admin/getcategoryList"
      );
      if (res.status === 200) {
        setcategoryList(res.data.allcategory);
      }
    } catch (error) {
      console.log(error);
      setcategoryList(error.response.data.allcategory);
    }
  };

  const [subcategoryList, setsubcategoryList] = useState([]);
  const getAllSubCategory = async () => {
    try {
      const res = await axios.get(
        "https://softwarelinks.in/api/admin/getsubcategoryList"
      );
      if (res.status === 200) {
        setsubcategoryList(res.data.allsubcategory);
      }
    } catch (error) {
      console.log(error);
      setsubcategoryList(error.response.data.allsubcategory);
    }
  };
  const [productTypes, setProductTypes] = useState([]);
  const getAllData = async () => {
    try {
      const res = await axios.get(
        "https://softwarelinks.in/api/vendor/producttype"
      );
      setProductTypes(res.data.success);
    } catch (error) {
      console.error(error);
    }
  };

  const [brandList, setbrandList] = useState([]);
  const getBrands = async () => {
    try {
      const res = await axios.get(
        "https://softwarelinks.in/api/admin/brandsList"
      );
      if (res.status === 200) {
        setbrandList(res.data.list);
      }
    } catch (error) {
      console.log(error);
      setbrandList(error.response.data.list);
    }
  };

  useEffect(() => {
    getAllCategory();
    getAllSubCategory();
    getAllData();
    getBrands();
  }, []);

  console.log("brandList", brandList);

  return (
    <div>
      <h4 style={{ backgroundColor: "#dae1f3" }} className="p-4 fw-bold mb-4">
        Edit Product
      </h4>
      <Container>
        <div className="row">
          <div className="col-lg-3">
            <label className="fw-bold text-dark">Product Type</label>
            <Form.Select
              aria-label="Default select example"
              value={item?.productType}
              //   onChange={(e) => setproductType(e.target.value)}
            >
              <option value={item?.productType}>{item?.productType}</option>
            </Form.Select>
          </div>

          <div className="col-lg-3">
            <label className="fw-bold text-dark">Product Name</label>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                placeholder={item?.productName}
                aria-describedby="basic-addon1"
                onChange={(e) => setproductName(e.target.value)}
              />
            </InputGroup>
          </div>

          <div className="col-lg-3">
            <label className="fw-bold text-dark">HSN</label>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                placeholder={item?.HSN}
                aria-describedby="basic-addon1"
                onChange={(e) => setHSN(e.target.value)}
              />
            </InputGroup>
          </div>

          <div className="col-lg-3">
            <label className="fw-bold text-dark">Batch</label>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                placeholder={item?.Batch}
                aria-describedby="basic-addon1"
                onChange={(e) => setBatch(e.target.value)}
              />
            </InputGroup>
          </div>

          {Vendor?.VendorType !== "Lab" ? (
            <>
              <div className="col-lg-3">
                <label className="fw-bold text-dark">Product Category</label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => setProductCategory(e.target.value)}
                >
                  <option>Select Product Category</option>
                  {categoryList?.map((item) => {
                    return (
                      <option value={item?._id}>{item?.categoryName}</option>
                    );
                  })}
                </Form.Select>
              </div>

              <div className="col-lg-3">
                <label className="fw-bold text-dark">
                  Product Sub-Category
                </label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => setProductSubCategory(e.target.value)}
                >
                  <option>Select Product Sub-Category</option>
                  {subcategoryList
                    ?.filter((sub) => sub?.categoryid?._id === ProductCategory)
                    .map((item) => {
                      return (
                        <option value={item?._id}>
                          {item?.subcategoryName}
                        </option>
                      );
                    })}
                </Form.Select>
              </div>
            </>
          ) : (
            <></>
          )}

          {item?.productType?.toLowerCase() === "tablet" ? (
            <>
              <div className="col-lg-3">
                <label className="fw-bold text-dark">
                  No. of Strips in Box
                </label>
                <InputGroup className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder={item?.No_of_Strips}
                    aria-describedby="basic-addon1"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(e) => setNo_of_Strips(e.target.value)}
                  />
                </InputGroup>
              </div>
              <div className="col-lg-3">
                <label className="fw-bold text-dark">
                  No. of Tablets in strips
                </label>
                <InputGroup className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder={item?.No_Tablets_strips}
                    aria-describedby="basic-addon1"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(e) => setNo_Tablets_strips(e.target.value)}
                  />
                </InputGroup>
              </div>
              <div className="col-lg-3">
                <label className="fw-bold text-dark">Scheme</label>
                <InputGroup className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder={item?.Scheme}
                    aria-describedby="basic-addon1"
                    onChange={(e) => setScheme(e.target.value)}
                  />
                </InputGroup>
              </div>
            </>
          ) : (
            ""
          )}
          {/* <div className="col-lg-3">
            <label className="fw-bold text-dark">Product Type Descprition*</label>
            <span></span>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                placeholder="description"
                aria-describedby="basic-addon1"
                onChange={(e) => setproductType(e.target.value)}
              />
            </InputGroup>
          </div> */}
          <div className="col-lg-3">
            <label className="fw-bold text-dark">Currency Type</label>
            <Form.Select
              aria-label="Default select example"
              onChange={(e) => setcurrencyFormat(e.target.value)}
            >
              <option>{item?.currencyFormat}</option>
              <option value="₹">₹</option>
              <option value="$">$</option>
            </Form.Select>
          </div>

          <div className="col-lg-3">
            <label className="fw-bold text-dark">
              {item?.productType?.toLowerCase() === "tablet"
                ? "Product Price/Strips"
                : "Product Price/Product"}
            </label>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                placeholder={item?.productPrice}
                aria-describedby="basic-addon1"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => setproductPrice(e.target.value)}
              />
            </InputGroup>
          </div>
          <div className="col-lg-3">
            <label className="fw-bold text-dark">SGST</label>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                placeholder={item?.SGST}
                aria-describedby="basic-addon1"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => setSGST(e.target.value)}
              />
            </InputGroup>
          </div>
          <div className="col-lg-3">
            <label className="fw-bold text-dark">CGST</label>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                placeholder={item?.CGST}
                aria-describedby="basic-addon1"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => setCGST(e.target.value)}
              />
            </InputGroup>
          </div>

          <div className="col-lg-3">
            <label className="fw-bold text-dark">MRP</label>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                placeholder={item?.MRP}
                aria-describedby="basic-addon1"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => setMRP(e.target.value)}
              />
            </InputGroup>
          </div>

          <div className="col-lg-3">
            <label className="fw-bold text-dark">Product Discount</label>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                placeholder={item?.discount}
                aria-describedby="basic-addon1"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => setdiscount(e.target.value)}
              />
            </InputGroup>
          </div>

          <div className="col-lg-3">
            <label className="fw-bold text-dark">Stock</label>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                placeholder={item?.stock}
                aria-describedby="basic-addon1"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => setstock(e.target.value)}
              />
            </InputGroup>
          </div>

          {Vendor?.VendorType !== "Lab" ? (
            <div className="col-lg-3">
              <label className="fw-bold text-dark">Product variant</label>
              <InputGroup className="mb-3">
                <Form.Control
                  type="text"
                  placeholder={item?.variant}
                  aria-describedby="basic-addon1"
                  onChange={(e) => setvariant(e.target.value)}
                />
              </InputGroup>
            </div>
          ) : (
            <></>
          )}

          <div className="col-lg-3">
            <label className="fw-bold text-dark">Manufacturing Date</label>
            <InputGroup className="mb-3">
              <Form.Control
                type="date"
                placeholder={item?.manufacturingDate}
                aria-describedby="basic-addon1"
                onChange={(e) => setmanufacturingDate(e.target.value)}
              />
            </InputGroup>
          </div>

          <div className="col-lg-3">
            <label className="fw-bold text-dark">Expiry Date</label>
            <InputGroup className="mb-3">
              <Form.Control
                type="date"
                placeholder={item?.expiryDate}
                aria-describedby="basic-addon1"
                onChange={(e) => setexpiryDate(e.target.value)}
              />
            </InputGroup>
          </div>

          <div className="col-lg-3">
            <label className="fw-bold text-dark">Product Size</label>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                placeholder={item?.productSize}
                aria-describedby="basic-addon1"
                onChange={(e) => setproductSize(e.target.value)}
              />
            </InputGroup>
          </div>

          <div className="col-lg-3">
            <label className="fw-bold text-dark">Pack Size</label>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                placeholder={item?.packSize}
                aria-describedby="basic-addon1"
                onChange={(e) => setpackSize(e.target.value)}
              />
            </InputGroup>
          </div>

          {Vendor?.VendorType !== "Lab" ? (
            <>
              <div className="col-lg-3">
                <label className="fw-bold text-dark">Colour</label>
                <InputGroup className="mb-3">
                  <Form.Control
                    type="SAC"
                    placeholder={item?.colour}
                    aria-describedby="basic-addon1"
                    onChange={(e) => setcolour(e.target.value)}
                  />
                </InputGroup>
              </div>

              <div className="col-lg-3">
                <label className="fw-bold text-dark">Flavour</label>
                <InputGroup className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder={item?.flavour}
                    aria-describedby="basic-addon1"
                    onChange={(e) => setflavour(e.target.value)}
                  />
                </InputGroup>
              </div>

              <div className="col-lg-3">
                <label className="fw-bold text-dark">Fragrance</label>
                <InputGroup className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder={item?.fragrance}
                    aria-describedby="basic-addon1"
                    onChange={(e) => setfragrance(e.target.value)}
                  />
                </InputGroup>
              </div>
            </>
          ) : (
            <></>
          )}

          <div className="col-lg-3">
            <label className="fw-bold text-dark">Description</label>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                placeholder={item?.description}
                aria-describedby="basic-addon1"
                onChange={(e) => setdescription(e.target.value)}
              />
            </InputGroup>
          </div>

          {Vendor?.VendorType !== "Lab" ? (
            <div className="col-lg-3">
              <label className="fw-bold text-dark">Brand</label>
              <InputGroup className="mb-3">
                {/* <Form.Control
                type="text"
                placeholder="brand"
                aria-describedby="basic-addon1"
                onChange={(e) => setbrand(e.target.value)}
              /> */}
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => setbrand(e.target.value)}
                >
                  <option>{item?.brand}</option>
                  {brandList?.map((brand) => (
                    <option value={brand?.brandName}>{brand?.brandName}</option>
                  ))}
                </Form.Select>
              </InputGroup>
            </div>
          ) : (
            <></>
          )}

          <div className="col-lg-3">
            <label className="fw-bold text-dark">Country Of Origin</label>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                placeholder={item?.countryOfOrigin}
                aria-describedby="basic-addon1"
                onChange={(e) => setcountryOfOrigin(e.target.value)}
              />
            </InputGroup>
          </div>

          <div className="col-lg-3">
            <label className="fw-bold text-dark">
              Manufacturer Company Name
            </label>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                placeholder={item?.manufacturercompanyname}
                aria-describedby="basic-addon1"
                onChange={(e) => setmanufacturercompanyname(e.target.value)}
              />
            </InputGroup>
          </div>

          <div className="col-lg-3">
            <label className="fw-bold text-dark">Manufacturer address</label>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                placeholder={item?.manufactureraddress}
                aria-describedby="basic-addon1"
                onChange={(e) => setmanufactureraddress(e.target.value)}
              />
            </InputGroup>
          </div>

          <div className="col-lg-3">
            <label className="fw-bold text-dark">Product Images</label>
            <InputGroup className="mb-3">
              <input
                type="file"
                id="files"
                name="files"
                multiple
                onChange={(e) => setproductImgs(e.target.files)}
              />
            </InputGroup>
          </div>
          <div className="col-lg-3">
            <label className="fw-bold text-dark">Safety Document</label>
            <InputGroup className="mb-3">
              <input
                type="file"
                id="files"
                name="files"
                onChange={(e) => setsafetyDoc(e.target.files[0])}
              />
            </InputGroup>
          </div>
          <div className="col-lg-3">
            <label className="fw-bold text-dark">Gst Document</label>
            <InputGroup className="mb-3">
              <input
                type="file"
                id="files"
                name="files"
                onChange={(e) => setGstDocument(e.target.files[0])}
              />
            </InputGroup>
          </div>
        </div>

        <div className="d-flex gap-4 mb-4 ">
          <Button
            style={{
              backgroundColor: "#208b8c",
              width: "200px",
              fontSize: "16px",
              height: "38px",
            }}
            onClick={(e) => EditProduct(e)}
          >
            Submit
          </Button>
        </div>
      </Container>
    </div>
  );
};
