import axios from "axios";
import React from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { FaEye } from "react-icons/fa6";
import { FaEyeSlash } from "react-icons/fa6";
import "./Adminpanel.css";
import { Link } from "react-router-dom";

export default function AdminLogin() {
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [passwordSeenStatus, setpasswordSeenStatus] = useState(false);

  const loginAdmin = async () => {
    try {
      const config = {
        url: "/admin/subadminLogin",
        method: "post",
        baseURL: "https://softwarelinks.in/api",
        headers: { "content-type": "application/json" },
        data: {
          email: email,
          password: password,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        sessionStorage.setItem(
          "adminDetails",
          JSON.stringify(res.data.subadmindata)
        );
        alert("Login successful");
        window.location.assign("/admin/dashboard");
      }
    } catch (error) {
      console.log(error);
      return alert(error.response.data.error);
    }
  };
  return (
    <div>
      <div
        className="row"
        style={{
          height: "300px",
          width: "500px",
          border: "1px solid aliceblue",
          borderRadius: "10px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          background: "aliceblue",
        }}
      >
        <div className="col-md-6 d-flex justify-content-center align-item-center">
          <img
            src="/Images/logo.png"
            alt="no-image"
            style={{ width: "236px" }}
          />
        </div>
        <div className="col-md-6 p-3">
          <div style={{ marginTop: "36px" }}>
            <h4
              style={{
                fontFamily: "monospace",
                fontWeight: "600",
                fontSize: "30px",
              }}
            >
              Admin Login
            </h4>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              {/* <Form.Label>Email address</Form.Label> */}
              <Form.Control
                type="email"
                placeholder="name@example.com"
                onChange={(e) => setemail(e.target.value)}
              />
            </Form.Group>
            <Form.Group
              className="mb-3 d-flex align-items-center"
              controlId="exampleForm.ControlInput1"
            >
              {/* <Form.Label>Password</Form.Label> */}
              <Form.Control
                type={!passwordSeenStatus ? "password" : "text"}
                placeholder="Enter password"
                onChange={(e) => setpassword(e.target.value)}
              />
              {!passwordSeenStatus ? (
                <FaEye
                  className="adminlogineye"
                  onClick={() => setpasswordSeenStatus(true)}
                />
              ) : (
                <FaEyeSlash
                  className="adminlogineye"
                  onClick={() => setpasswordSeenStatus(false)}
                />
              )}
            </Form.Group>
            <div className="adminloginForgotpwd">
              <Link to={"/admin-forgot-password"}>
                <div>Forgot Password</div>
              </Link>
            </div>

            <div className="d-flex justify-content-center mt-3">
              <Button onClick={loginAdmin}>Login</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
