import {
  faEye,
  faFilePdf,
  faPrint,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

export const PatientBilling = () => {
  const [show, setShow] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const navigate = useNavigate();
  let details = JSON.parse(sessionStorage.getItem("PatientUser"));

  let [PatientDetails, setPatientDetails] = useState({});
  const getPatientByidForBill = (id) => {
    axios
      .get(
        "https://softwarelinks.in/api/user/getPatiendByIdForBillDetails/" + id
      )
      .then(function (response) {
        console.log("hfhahahahhahahaahahah: ", response.data.patientdetails);
        setPatientDetails(response.data.patientdetails);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  //   function gotoBillInvoice() {}

  useEffect(() => {
    getPatientByidForBill(details?._id);
  }, [Object.keys(details).length]);

  console.log("patientdetails: ", PatientDetails);

  return (
    <div>
      <h4
        style={{ backgroundColor: "#dae1f3" }}
        className="p-4 fw-bold mb-4 mt-2"
      >
        Billing
      </h4>

      <Table responsive="md" style={{ marginTop: "1%" }}>
        <thead>
          <tr style={{ fontSize: "15px", textAlign: "center" }}>
            {/* <th>Profile</th> */}
            <th>Sno.</th>
            <th>Patient-Id</th>

            <th> Name</th>
            <th>Sex</th>
            <th>Address</th>
            <th>Mobile</th>
            <th>Age</th>

            <th>View Billing</th>
          </tr>
        </thead>
        <tbody>
          <tr style={{ fontSize: "15px", textAlign: "center" }}>
            <td>{1}</td>
            <td>{PatientDetails?.PatientId}</td>
            <td>{PatientDetails?.Firstname}</td>
            <td>{PatientDetails?.Gender}</td>

            <td>{PatientDetails?.Address1}</td>
            <td>{PatientDetails?.PhoneNumber}</td>
            <td>{PatientDetails?.DOB}</td>

            <td>
              <Button
                style={{
                  backgroundColor: "#20958c",
                  color: "white",
                }}
                onClick={() => {
                  setShow5(true);
                }}
              >
                View Billing
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>

      {/* View both bill and casuse info */}
      <Modal size="md" show={show5} onHide={handleClose5}>
        <Modal.Header>
          <Modal.Title>View Billing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table responsive="md" bordered style={{ marginTop: "1%" }}>
            <thead>
              <tr style={{ fontSize: "15px", textAlign: "center" }}>
                <th>S.No</th>
                <th>Cause Name</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {PatientDetails?.cause?.map((item, i) => {
                return (
                  <tr style={{ fontSize: "15px", textAlign: "center" }}>
                    <td>{i + 1}.</td>

                    <td>{item.CauseName}</td>

                    <td>
                      <div className="d-flex gap-3">
                        <button
                          onClick={() => {
                            navigate("/PatientBillingInvoice", {
                              state: {
                                patientCauseInfo: item,
                                patientdetails: PatientDetails,
                              },
                            });
                          }}
                          className="fs-5"
                          style={{ border: "none" }}
                        >
                          <FaEye
                            style={{ color: "#20958c", marginRight: "1%" }}
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </div>
  );
};
