import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";

function SurgryDetails() {
  let details = JSON.parse(sessionStorage.getItem("PatientUser"));

  const dobString = details?.DOB;
  const dob = new Date(dobString);
  const currentDate = new Date();
  const differenceMs = currentDate - dob;
  const ageYears = Math.floor(differenceMs / (1000 * 60 * 60 * 24 * 365.25));

  let ageOutput;
  if (ageYears < 1) {
    const ageMonths = Math.floor(ageYears * 12);
    ageOutput = `${ageMonths} months`;
  } else {
    ageOutput = `${ageYears} years`;
  }

  const [data, setdata] = useState([]);

  const GetSurgeryPatients = async () => {
    try {
      const res = await axios.get(
        "https://softwarelinks.in/api/admin/GetSurgeryPatients"
      );
      if (res.status === 200) {
        setdata(
          res.data.success?.filter(
            (item) => item?.PatientId?._id === details?._id
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetSurgeryPatients();
  }, []);
  const [SelectedData, setSelectedData] = useState({});

  const [showaccepted, setShowaccepted] = useState(false);
  const handleCloseaccepted = () => setShowaccepted(false);
  const handleShowaccepted = (item) => {
    setShowaccepted(true);
    setSelectedData(item);
  };

  const [showrejected, setShowrejected] = useState(false);
  const handleCloserejected = () => setShowrejected(false);
  const handleShowrejected = (item) => {
    setShowrejected(true);
    setSelectedData(item);
  };

  const [SurgeryR, setSurgeryR] = useState({});
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (item) => {
    setShow(true);
    setSurgeryR(item);
  };

  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "LabTestInvoice",
  });

  console.log("data", data);
  console.log("SurgeryR", SurgeryR);

  return (
    <div>
      <h4
        style={{ backgroundColor: "#dae1f3" }}
        className="p-4 fw-bold mb-4 mt-2"
      >
        Surgery
      </h4>

      <Table responsive bordered>
        <thead>
          <tr className="admin-table-head">
            <th>S.No</th>
            <th>Patient Name</th>
            <th>PatientId</th>
            <th>Cause Name</th>
            <th>Surgery Name</th>
            <th>Department</th>
            <th>Refered By</th>
            <th>Refered To</th>
            <th>Reason For Surgery</th>
            <th>Status</th>
            <th>Report</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, i) => {
            return (
              <tr style={{ fontSize: "15px", textAlign: "center" }}>
                <td>{i + 1}</td>
                <td>{item?.PatientName}</td>
                <td>{item?.PatientId?.PatientId}</td>
                <td>{item?.CauseName}</td>
                <td>{item?.SurgeryName}</td>
                <td>{item?.DepartmentName}</td>
                <td>{item?.ReferedDoctorName}</td>
                <td>{item?.DoctorName}</td>
                <td>{item?.ReasonForSurgery}</td>
                <td>
                  {item?.Status === "Accepted" ? (
                    <>
                      <button
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                        }}
                        onClick={() => handleShowaccepted(item)}
                      >
                        <i class="fas fa-eye"></i>
                      </button>
                    </>
                  ) : item?.Status === "Rejected" ? (
                    <>
                      <button
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                        }}
                        onClick={() => handleShowrejected(item)}
                      >
                        <i class="fas fa-eye" style={{ color: "red" }}></i>
                      </button>
                    </>
                  ) : (
                    <>
                      <div className="d-flex gap-5 fs-5">
                        <Button style={{ backgroundColor: "#20958c" }}>
                          Waiting for Surgeon Confirmation
                        </Button>
                      </div>
                    </>
                  )}
                </td>
                <td>
                  {item?.SurgeryReport?.length > 0 ? (
                    <button
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                      onClick={() => handleShow(item)}
                    >
                      <i class="fas fa-eye" style={{ color: "#20958c" }}></i>
                    </button>
                  ) : (
                    "-"
                  )}{" "}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div>
        <Modal
          size="md"
          show={showaccepted}
          onHide={handleCloseaccepted}
          className="custom-modal"
        >
          <Modal.Header>
            <Modal.Title>Surgery Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row" style={{ padding: "10px" }}>
              <Row>
                <Col md={6}>
                  <Row>
                    <Col md={4}>
                      <label style={{ color: "white" }}>Surgery Date</label>
                      <Form.Control
                        value={SelectedData?.SurgeryDate}
                        aria-label="Default select example"
                      />
                    </Col>
                    <Col md={4}>
                      <label style={{ color: "white" }}>Start Time</label>

                      <Form.Control
                        value={SelectedData?.StartTime}
                        aria-label="Default select example"
                      />
                    </Col>
                    <Col md={4}>
                      <label style={{ color: "white" }}>End Time</label>

                      <Form.Control
                        value={SelectedData?.EndTime}
                        aria-label="Default select example"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <label style={{ color: "white" }}>Department</label>

                  <Form.Control
                    value={SelectedData?.DepartmentName}
                    aria-label="Default select example"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <label style={{ color: "white" }}>Surgeon</label>

                  <Form.Control
                    value={`${SelectedData?.Surgeon?.Firstname} ${SelectedData?.Surgeon?.Lastname}`}
                    aria-label="Default select example"
                  />
                </Col>
                <Col md={6}>
                  <label style={{ color: "white" }}>Assistant-1</label>

                  <Form.Control
                    value={`${SelectedData?.Assistant1?.Firstname} ${SelectedData?.Assistant1?.Lastname}`}
                    aria-label="Default select example"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <label style={{ color: "white" }}> Assistant-2</label>

                  <Form.Control
                    value={`${SelectedData?.Assistant2?.Firstname} ${SelectedData?.Assistant2?.Lastname}`}
                    aria-label="Default select example"
                  />
                </Col>
                <Col md={6}>
                  <label style={{ color: "white" }}>Anaesthetist</label>

                  <Form.Control
                    value={`${SelectedData?.Anaesthetist?.Firstname} ${SelectedData?.Anaesthetist?.Lastname}`}
                    aria-label="Default select example"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <label style={{ color: "white" }}>Scrub Nurse</label>
                  <Form.Control
                    value={`${SelectedData?.ScrubNurse?.Firstname} ${SelectedData?.ScrubNurse?.Lastname}`}
                    aria-label="Default select example"
                  />
                </Col>
                <Col md={6}>
                  <label style={{ color: "white" }}>Circ Nurse</label>

                  <Form.Control
                    value={`${SelectedData?.CircNurse?.Firstname} ${SelectedData?.CircNurse?.Lastname}`}
                    aria-label="Default select example"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <label style={{ color: "white" }}>OT Room</label>

                  <Form.Control
                    value={SelectedData?.OTRoom?.Name}
                    aria-label="Default select example"
                  />
                </Col>
                <Col md={6}></Col>
              </Row>

              <label style={{ color: "white" }}>Note</label>
              <Form.Group className="mb-3">
                <Form.Control
                  value={SelectedData?.Note}
                  aria-label="Default select example"
                />
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex gap-2">
              <Button
                variant="secondary"
                onClick={() => setShowaccepted(false)}
              >
                Cancel
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal size="md" show={showrejected} onHide={handleCloserejected}>
          <Modal.Header>
            <Modal.Title>Rejection reason for surgery </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row" style={{ padding: "10px" }}>
              <p style={{ color: "white" }}>
                {SelectedData?.REasonForRejection}
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex gap-2">
              <Button
                variant="secondary"
                onClick={() => setShowrejected(false)}
              >
                Cancel
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal show={show} onHide={handleClose} className="custom-modal">
          <Modal.Header closeButton>
            <Modal.Title>Surgery Report</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: "white" }}>
            <div ref={componentRef}>
              <div style={{ overflow: "hidden", overflowX: "scroll" }}>
                <div
                  id="pdf"
                  style={{
                    padding: "15px",
                    overflow: "hidden",
                    backgroundColor: "white",
                  }}
                >
                  <div
                    style={{
                      padding: "5px",
                      border: "2px solid #20958C",
                      margin: "auto",
                      borderRadius: "20px",
                    }}
                  >
                    <div className="d-flex align-items-center mb-1 justify-content-around ps-5 pe-5 pt-4">
                      <div className="d-flex align-items-center">
                        <img
                          src="/img/logo.jpg"
                          alt=""
                          style={{ width: "100px" }}
                        />
                      </div>
                      <div className="text-center">
                        <h4 className="fw-bold" style={{ fontSize: "25px" }}>
                          JANANI MULTISPECIALITY HOSPITAL AND RESEARCH CENTER
                        </h4>
                        <h6 className="fw-bold" style={{ fontSize: "19px" }}>
                          Beside Canara Bank, Jalanagar Main Road, K. K. Colony,
                          Vijaypura-586109
                        </h6>
                        <h6 style={{ fontSize: "16px" }}>
                          Tel:08352-277077 Cell:9606031158, 7090831204
                          Email:jananihospital2018@gmail.com
                        </h6>
                      </div>
                    </div>
                    <div
                      className="text-center"
                      style={{
                        borderBottom: "1px solid #20958C",
                        width: "100%",
                        textAlign: "center",
                      }}
                    ></div>
                    <div className="text-center mt-1">
                      {" "}
                      <h6
                        className="fw-bold mt-2"
                        style={{ color: "#20958C", fontSize: "30px" }}
                      >
                        SURGERY REPORT
                      </h6>
                    </div>
                    <div
                      className="text-center"
                      style={{
                        borderBottom: "1px solid #20958C",
                        width: "100%",
                        textAlign: "center",
                      }}
                    ></div>
                    <div
                      className="mt-2"
                      style={{
                        paddingLeft: "42px",
                        paddingRight: "42px",
                        textAlign: "justify",
                      }}
                    >
                      <Table
                        style={{
                          borderCollapse: "collapse",
                          width: "100%",
                          margin: "auto",
                          marginBottom: "20px",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              colSpan={2}
                              style={{
                                width: "33%",
                                border: "2px  solid #20958C",
                              }}
                            >
                              Name:{" "}
                              {`${SurgeryR?.PatientId?.Firstname} ${SurgeryR?.PatientId?.Lastname}`}
                            </td>
                            <td
                              colSpan={2}
                              style={{
                                width: "33%",
                                border: "2px  solid #20958C",
                              }}
                            >
                              Age: {ageOutput}
                            </td>
                            <td
                              colSpan={2}
                              style={{
                                width: "33%",
                                border: "2px  solid #20958C",
                              }}
                            >
                              Sex: {SurgeryR?.PatientId?.Gender}
                            </td>
                          </tr>
                          <tr>
                            <td
                              colSpan={3}
                              style={{
                                width: "50%",
                                border: "2px  solid #20958C",
                              }}
                            >
                              Pt ID: {SurgeryR?.PatientId?.PatientId}
                            </td>
                            <td
                              colSpan={3}
                              style={{
                                width: "50%",
                                border: "2px  solid #20958C",
                              }}
                            >
                              Ward: {SurgeryR?.OtRoom?.Name}
                            </td>
                          </tr>

                          <tr>
                            <td
                              colSpan={2}
                              style={{
                                width: "33%",
                                border: "2px  solid #20958C",
                              }}
                            >
                              Surgeon:
                              {SurgeryR?.SurgeryReport?.length > 0
                                ? SurgeryR?.SurgeryReport[
                                    SurgeryR?.SurgeryReport?.length - 1
                                  ]?.Surgeon
                                : ""}
                            </td>
                            <td
                              colSpan={2}
                              style={{
                                width: "33%",
                                border: "2px  solid #20958C",
                              }}
                            >
                              Assistant-1:{" "}
                              {SurgeryR?.SurgeryReport?.length > 0
                                ? SurgeryR?.SurgeryReport[
                                    SurgeryR?.SurgeryReport?.length - 1
                                  ]?.Assistant1
                                : ""}
                            </td>
                            <td
                              colSpan={2}
                              style={{
                                width: "33%",
                                border: "2px  solid #20958C",
                              }}
                            >
                              Assistant-2:{" "}
                              {SurgeryR?.SurgeryReport?.length > 0
                                ? SurgeryR?.SurgeryReport[
                                    SurgeryR?.SurgeryReport?.length - 1
                                  ]?.Assistant2
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td
                              colSpan={2}
                              style={{
                                width: "33%",
                                border: "2px  solid #20958C",
                              }}
                            >
                              Anaesthetist:{" "}
                              {SurgeryR?.SurgeryReport?.length > 0
                                ? SurgeryR?.SurgeryReport[
                                    SurgeryR?.SurgeryReport?.length - 1
                                  ]?.Anaesthetist
                                : ""}
                            </td>
                            <td
                              colSpan={2}
                              style={{
                                width: "33%",
                                border: "2px  solid #20958C",
                              }}
                            >
                              Scrub Nurse:{" "}
                              {SurgeryR?.SurgeryReport?.length > 0
                                ? SurgeryR?.SurgeryReport[
                                    SurgeryR?.SurgeryReport?.length - 1
                                  ]?.ScrubNurse
                                : ""}
                            </td>
                            <td
                              colSpan={2}
                              style={{
                                width: "33%",
                                border: "2px  solid #20958C",
                              }}
                            >
                              Circ Nurse:{" "}
                              {SurgeryR?.SurgeryReport?.length > 0
                                ? SurgeryR?.SurgeryReport[
                                    SurgeryR?.SurgeryReport?.length - 1
                                  ]?.CircNurse
                                : ""}
                            </td>
                          </tr>

                          <tr>
                            <td
                              colSpan={2}
                              style={{
                                width: "16.6%",
                                border: "2px  solid #20958C",
                              }}
                            >
                              Skin Wound Condition:{" "}
                            </td>
                            <td
                              style={{
                                width: "16.6%",
                                border: "2px  solid #20958C",
                              }}
                              colSpan={4}
                            >
                              {SurgeryR?.SurgeryReport?.length > 0
                                ? SurgeryR?.SurgeryReport[
                                    SurgeryR?.SurgeryReport?.length - 1
                                  ]?.SkinWoundCondition
                                : ""}
                            </td>
                          </tr>
                        </tbody>
                      </Table>

                      <Table>
                        <tr>
                          <td
                            colspan="2"
                            style={{
                              border: "2px  solid #20958C",
                            }}
                          >
                            <div>
                              <b>Pre-Operative Operation : </b>{" "}
                              {SurgeryR?.SurgeryReport?.length > 0
                                ? SurgeryR?.SurgeryReport[
                                    SurgeryR?.SurgeryReport?.length - 1
                                  ]?.PreOperativeDiagnosis
                                : ""}
                            </div>
                          </td>
                          <td
                            style={{
                              border: "2px  solid #20958C",
                            }}
                          >
                            <div>
                              <b>Name of Operaton : </b>{" "}
                              {SurgeryR?.SurgeryReport?.length > 0
                                ? SurgeryR?.SurgeryReport[
                                    SurgeryR?.SurgeryReport?.length - 1
                                  ]?.NameofOperation
                                : ""}
                            </div>
                          </td>
                        </tr>
                        <tr style={{ border: "2px  solid #20958C" }}>
                          <b>Procedure : </b>
                          {SurgeryR?.SurgeryReport?.length > 0
                            ? SurgeryR?.SurgeryReport[
                                SurgeryR?.SurgeryReport?.length - 1
                              ]?.Procedure
                            : ""}
                        </tr>
                        <tr style={{ border: "2px  solid #20958C" }}>
                          <b>Findings : </b>
                          {SurgeryR?.SurgeryReport?.length > 0
                            ? SurgeryR?.SurgeryReport[
                                SurgeryR?.SurgeryReport?.length - 1
                              ]?.Findings
                            : ""}
                        </tr>
                        <tr>
                          <td style={{ border: "2px  solid #20958C" }}>
                            <b>Swab / Instruments : </b>{" "}
                          </td>
                          <td style={{ border: "2px  solid #20958C" }}>
                            {SurgeryR?.SurgeryReport?.length > 0
                              ? SurgeryR?.SurgeryReport[
                                  SurgeryR?.SurgeryReport?.length - 1
                                ]?.ReportCheck
                              : ""}
                          </td>
                          <td style={{ border: "2px  solid #20958C" }}>
                            Doctor's Signature
                            {SurgeryR?.SurgeryReport?.length > 0 ? (
                              <img
                                src={`https://softwarelinks.in/PatientREG/${
                                  SurgeryR?.SurgeryReport[
                                    SurgeryR?.SurgeryReport?.length - 1
                                  ]?.Doctorsignature
                                }`}
                              />
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleprint}>
              Print
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default SurgryDetails;
