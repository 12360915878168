import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useMemo } from "react";
import { Button, Container, Table } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";

export const PatientBillInvoice = () => {
  const location = useLocation();
  let { patientCauseInfo, patientdetails } = location["state"];
  console.log("sabval: ", patientCauseInfo, patientdetails);
  // to print the pdf ----->
  // const createPDF = async () => {
  //   // setRotate(360);
  //   const pdf = new jsPDF("potrait", "pt", "a4");
  //   const data = await html2canvas(document.querySelector("#pdf"));
  //   const img = data.toDataURL("image/png");
  //   const imgProperties = pdf.getImageProperties(img);
  //   const pdfWidth = pdf.internal.pageSize.getWidth();
  //   const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
  //   pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
  //   pdf.save("Final Bill.pdf");
  // };

  const createPDF = async () => {
    const pdfElement = document.querySelector("#pdf");

    // Ensure images are loaded
    const loadImages = () => {
      return new Promise((resolve) => {
        const images = pdfElement.querySelectorAll("img");
        let loadedCount = 0;
        images.forEach((img) => {
          if (img.complete) {
            loadedCount++;
            if (loadedCount === images.length) resolve();
          } else {
            img.onload = () => {
              loadedCount++;
              if (loadedCount === images.length) resolve();
            };
          }
        });
      });
    };

    await loadImages(); // Wait for all images to load

    // Create the canvas
    const canvas = await html2canvas(pdfElement, {
      scale: 2, // Increase resolution
      useCORS: true, // Enable cross-origin for images
    });

    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("portrait", "pt", "a4");

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const imgProperties = pdf.getImageProperties(imgData);
    const imgHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    let position = 0;
    let heightLeft = imgHeight;

    pdf.addImage(imgData, "PNG", 0, position, pdfWidth, imgHeight);
    heightLeft -= pdf.internal.pageSize.getHeight();

    // Add pages if necessary
    while (heightLeft > 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, "PNG", 0, position, pdfWidth, imgHeight);
      heightLeft -= pdf.internal.pageSize.getHeight();
    }

    pdf.save("Final Bill.pdf");
  };

  let bedTotal = useMemo(() => {
    let total = 0;
    patientCauseInfo?.causeBillDetails[0]?.BedBillDetails.map((val, i) => {
      if (
        patientCauseInfo?.causeBillDetails[0]?.BedBillDetails?.length - 1 ===
        i
      ) {
        total +=
          Math.ceil(
            (new Date().getTime() - new Date(val?.bedAssignDate).getTime()) /
              (1000 * 60 * 60 * 24)
          ) * val?.bedcostperday;
      } else {
        total +=
          Math.ceil(
            (new Date(
              patientCauseInfo?.causeBillDetails[0]?.BedBillDetails[i + 1][
                "bedAssignDate"
              ]
            ).getTime() -
              new Date(val?.bedAssignDate).getTime()) /
              (1000 * 60 * 60 * 24)
          ) * val?.bedcostperday;
      }
    });
    return total;
  }, []);

  let pharmacyTotalPaid = useMemo(() => {
    let total = 0;
    patientCauseInfo?.causeBillDetails[0]?.pharmacyBillDetails.forEach(
      (data) => {
        if (data.PaymentMethod !== "Pay later") {
          total += data.Products?.reduce((acc, val) => {
            return acc + val?.Totalamount;
          }, 0);
        }
      }
    );
    return total;
  }, []);

  let pharmacyTotalUnPaid = useMemo(() => {
    let total = 0;
    patientCauseInfo?.causeBillDetails[0]?.pharmacyBillDetails.forEach(
      (data) => {
        if (data.PaymentMethod === "Pay later") {
          total += data.Products?.reduce((acc, val) => {
            return acc + val?.Totalamount;
          }, 0);
        }
      }
    );
    return total;
  }, []);

  let [labTotalPaid, labTotalUnPaid] = useMemo(() => {
    let totalPaid = 0;
    let totalUnPaid = 0;
    patientCauseInfo?.causeBillDetails[0]?.LabTestBillDetails.forEach(
      (data) => {
        if (data.labbookingId.paymentStatus === "PAID") {
          totalPaid += data?.labbookingId?.Labtests?.reduce((acc, val) => {
            if (patientdetails?.haveInsurance) {
              return acc + val?.priceInsurance;
            } else {
              return acc + val?.priceNonInsurance;
            }
          }, 0);
        } else {
          totalUnPaid += data?.labbookingId?.Labtests?.reduce((acc, val) => {
            if (patientdetails?.haveInsurance) {
              return acc + val?.priceInsurance;
            } else {
              return acc + val?.priceNonInsurance;
            }
          }, 0);
        }
      }
    );
    return [totalPaid, totalUnPaid];
  }, []);

  let otherBillDetailsPaid = useMemo(() => {
    return patientCauseInfo?.causeBillDetails[0]?.otherBillDetails.reduce(
      (acc, data) => {
        if (data.PaymentMethod !== "Pay later") {
          return acc + data?.price;
        }
        return acc;
      },
      0
    );
  }, []);

  let otherBillDetailsUnPaid = useMemo(() => {
    return patientCauseInfo?.causeBillDetails[0]?.otherBillDetails.reduce(
      (acc, data) => {
        if (data.PaymentMethod === "Pay later") {
          return acc + data?.price;
        }
        return acc;
      },
      0
    );
  }, []);

  let surgeryBillDetails = useMemo(() => {
    return patientCauseInfo?.causeBillDetails[0]?.SurgeryBillDetails.reduce(
      (acc, data) => {
        if (patientdetails?.haveInsurance) {
          return acc + data?.SurgeryAmountForInsurance;
        } else {
          return acc + data?.SurgeryAmountForNonInsurance;
        }
      },
      0
    );
  }, []);

  console.log(
    "lkolkolkol: ",
    bedTotal,
    pharmacyTotalPaid,
    pharmacyTotalUnPaid,
    labTotalPaid,
    labTotalUnPaid,
    otherBillDetailsPaid,
    otherBillDetailsUnPaid
  );
  return (
    <div>
      <Container className="mt-3">
        <div className="mb-3">
          <Button
            className="all-bg-green"
            onClick={async () => await createPDF()}
          >
            <FontAwesomeIcon icon={faPrint} className="me-2" /> Print Invoice
          </Button>
        </div>

        <div id="pdf" style={{ overflow: "hidden", overflowX: "scroll" }}>
          <div
            className="invoice-rspns"
            style={{
              //   background: "#f5f6fa",
              backdropFilter: "blur(4px)",
              padding: "60px",
            }}
          >
            <div className="">
              <div
                className="mb-5 "
                style={{
                  display: "flex",
                }}
              >
                <div>
                  <img
                    style={{ width: "115px", height: "115px" }}
                    className="logo me-2 "
                    src="/img/logo.png"
                    alt="Logo"
                  />{" "}
                </div>
                <div className="text-center" style={{ marginLeft: "30px" }}>
                  <span
                    className="fw-bold fs-4"
                    style={{ color: "rgb(32 139 140)" }}
                  >
                    JANANI Final Bill
                  </span>
                  <br />
                  <div>
                    <b>
                      Upstair Canara bank , Near BDA Cross, KK Colony, Jalanagar
                      Main Road, Vijayapur--586109
                    </b>
                    <div>
                      Phone:- 08352-277077 ,9606831158 , Email:-
                      jananihospital2018@gmail.com
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* top-bar closes */}

            <div style={{ border: "2px solid #20958C", padding: "10px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <b>IPD Final Bill Details</b>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Table bordered>
                  <tbody>
                    <tr>
                      <td>Patient Id</td>
                      <td>
                        <b>{patientdetails?.PatientId}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>Patient Name</td>
                      <td>
                        <b>
                          {patientdetails?.Firstname} {patientdetails?.Lastname}
                        </b>
                      </td>
                    </tr>
                    <tr>
                      <td>D.O.B</td>
                      <td>
                        <b>{patientdetails?.DOB}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>Contact No.</td>
                      <td>
                        <b>{patientdetails?.PhoneNumber}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>D.O.A</td>
                      <td>
                        <b>{`${new Date(patientdetails?.AdmitDate)
                          .getFullYear()
                          .toString()
                          .padStart(2, "0")}-${(
                          new Date(patientdetails?.AdmitDate).getMonth() + 1
                        )
                          .toString()
                          .padStart(2, "0")}-${new Date(
                          patientdetails?.AdmitDate
                        )
                          .getDate()
                          .toString()
                          .padStart(2, "0")}`}</b>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Table bordered>
                  <tbody>
                    <tr>
                      <td>Bill Date</td>
                      <td>
                        <b>{`${new Date()
                          .getFullYear()
                          .toString()
                          .padStart(2, "0")}-${(new Date().getMonth() + 1)
                          .toString()
                          .padStart(2, "0")}-${new Date()
                          .getDate()
                          .toString()
                          .padStart(2, "0")}`}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>Ward Name</td>
                      <td>
                        <b>
                          {
                            patientCauseInfo?.causeBillDetails[0]
                              ?.BedBillDetails[
                              patientCauseInfo?.causeBillDetails[0]
                                ?.BedBillDetails?.length - 1
                            ]?.wardName
                          }
                        </b>
                      </td>
                    </tr>
                    <tr>
                      <td>Bed No.</td>
                      <td>
                        <b>
                          {
                            patientCauseInfo?.causeBillDetails[0]
                              ?.BedBillDetails[
                              patientCauseInfo?.causeBillDetails[0]
                                ?.BedBillDetails?.length - 1
                            ]?.bedName
                          }
                        </b>
                      </td>
                    </tr>
                    <tr>
                      <td>Building & floor</td>
                      <td>
                        <b>
                          {
                            patientCauseInfo?.causeBillDetails[0]
                              ?.BedBillDetails[
                              patientCauseInfo?.causeBillDetails[0]
                                ?.BedBillDetails?.length - 1
                            ]?.buildingName
                          }{" "}
                          &{" "}
                          {
                            patientCauseInfo?.causeBillDetails[0]
                              ?.BedBillDetails[
                              patientCauseInfo?.causeBillDetails[0]
                                ?.BedBillDetails?.length - 1
                            ]?.floor
                          }
                        </b>
                      </td>
                    </tr>
                    <tr>
                      <td>D.O.D</td>
                      <td>
                        <b>
                          {patientCauseInfo?.isDischarged ? (
                            <>{`${new Date(
                              patientCauseInfo?.dischargeDate
                            ).getFullYear()}-${
                              new Date(
                                patientCauseInfo?.dischargeDate
                              ).getMonth() + 1
                            }-${new Date(
                              patientCauseInfo?.dischargeDate
                            ).getDate()}`}</>
                          ) : (
                            <>--/--</>
                          )}
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div
                style={{ border: "1px solid gray ", marginBottom: "10px" }}
              ></div>

              <Table bordered>
                <thead>
                  <th>Service Provided</th>
                  {/* <th>Charges</th>
                  <th>Nos</th>
                  <th>Amount </th> */}
                  <th>Details</th>
                </thead>

                <tbody>
                  {/* {patientCauseInfo?.causeBillDetails[0]?.map((item, i) => {
                    return ( */}
                  {patientCauseInfo?.causeBillDetails[0]?.pharmacyBillDetails
                    ?.length ? (
                    <tr>
                      <td>Bed Charges</td>
                      {/* <td>{bedTotal}</td>
                    <td>--/--</td>
                    <td>{bedTotal}</td> */}
                      <td>
                        <Table bordered>
                          <thead>
                            <th>Building</th>
                            <th>Floor</th>
                            <th>Ward</th>
                            <th>Bed</th>
                            <th>Bed Assigned Date</th>
                            <th>Number Of Days</th>
                            <th>Charges</th>
                            <th>Status</th>
                          </thead>
                          <tbody>
                            {patientCauseInfo?.causeBillDetails[0]?.BedBillDetails?.map(
                              (val, i) => {
                                return (
                                  <tr>
                                    <td>{val?.buildingName}</td>
                                    <td>{val?.floor}</td>
                                    <td>{val?.wardName}</td>
                                    <td>{val?.bedName}</td>
                                    <td>{`${new Date(
                                      val?.bedAssignDate
                                    ).getDate()}-${
                                      new Date(val?.bedAssignDate).getMonth() +
                                      1
                                    }-${new Date(
                                      val?.bedAssignDate
                                    ).getFullYear()}`}</td>

                                    <td>
                                      {patientCauseInfo?.causeBillDetails[0]
                                        ?.BedBillDetails?.length -
                                        1 ===
                                      i ? (
                                        <>
                                          {Math.ceil(
                                            (new Date().getTime() -
                                              new Date(
                                                val?.bedAssignDate
                                              ).getTime()) /
                                              (1000 * 60 * 60 * 24)
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {Math.ceil(
                                            (new Date(
                                              patientCauseInfo?.causeBillDetails[0]?.BedBillDetails[
                                                i + 1
                                              ]["bedAssignDate"]
                                            ).getTime() -
                                              new Date(
                                                val?.bedAssignDate
                                              ).getTime()) /
                                              (1000 * 60 * 60 * 24)
                                          )}
                                        </>
                                      )}
                                    </td>
                                    <td>
                                      {patientCauseInfo?.causeBillDetails[0]
                                        ?.BedBillDetails?.length -
                                        1 ===
                                      i ? (
                                        <>
                                          {Math.ceil(
                                            (new Date().getTime() -
                                              new Date(
                                                val?.bedAssignDate
                                              ).getTime()) /
                                              (1000 * 60 * 60 * 24)
                                          ) * val?.bedcostperday}
                                        </>
                                      ) : (
                                        <>
                                          {Math.ceil(
                                            (new Date(
                                              patientCauseInfo?.causeBillDetails[0]?.BedBillDetails[
                                                i + 1
                                              ]["bedAssignDate"]
                                            ).getTime() -
                                              new Date(
                                                val?.bedAssignDate
                                              ).getTime()) /
                                              (1000 * 60 * 60 * 24)
                                          ) * val?.bedcostperday}
                                        </>
                                      )}
                                    </td>
                                    <td>Pay later</td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  {patientCauseInfo?.causeBillDetails[0]?.pharmacyBillDetails
                    ?.length ? (
                    <tr>
                      <td>Pharmacy Charges</td>
                      {/* <td>{pharmacyTotalPaid + pharmacyTotalUnPaid}</td> */}
                      {/* <td>--/--</td>*/}
                      {/* <td>{pharmacyTotalPaid + pharmacyTotalUnPaid}</td> */}
                      <td>
                        <Table bordered>
                          <thead>
                            <th>Product Name</th>
                            <th>Quantity</th>
                            <th>Product Price</th>
                            <th>Discount</th>
                            <th>Total Amount</th>
                            <th>status</th>
                          </thead>
                          <tbody>
                            {patientCauseInfo?.causeBillDetails[0]?.pharmacyBillDetails?.map(
                              (val, i) => {
                                return (
                                  <>
                                    {val?.Products?.map((valdata) => {
                                      return (
                                        <tr>
                                          <td>{valdata?.productname}</td>
                                          <td>{valdata?.quantity}</td>
                                          <td>{valdata?.productPrice}</td>
                                          <td>{valdata?.discount}</td>
                                          <td>{valdata?.Totalamount}</td>
                                          <td>{val?.PaymentMethod}</td>
                                        </tr>
                                      );
                                    })}
                                  </>
                                );
                              }
                            )}
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  {patientCauseInfo?.causeBillDetails[0]?.LabTestBillDetails
                    ?.length ? (
                    <tr>
                      <td>Lab Charges</td>
                      {/* <td>{labTotalPaid + labTotalUnPaid}</td> */}
                      {/* <td>--/--</td>
                    <td>{labTotalPaid + labTotalUnPaid}</td> */}
                      <td>
                        <Table bordered>
                          <thead>
                            <th>Lab Test Name</th>
                            <th>charges</th>
                            <th>Status</th>
                          </thead>
                          <tbody>
                            {patientCauseInfo?.causeBillDetails[0]?.LabTestBillDetails?.map(
                              (val, i) => {
                                return (
                                  <>
                                    {val?.labbookingId?.Labtests?.map(
                                      (valdata) => {
                                        return (
                                          <tr>
                                            <td>{valdata?.testName}</td>
                                            <td>
                                              {patientdetails?.haveInsurance
                                                ? valdata?.priceInsurance
                                                : valdata?.priceNonInsurance}
                                            </td>
                                            <td>
                                              {val?.labbookingId
                                                ?.paymentStatus === "UNPAID" ? (
                                                <>Pay later</>
                                              ) : (
                                                <>Paid</>
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </>
                                );
                              }
                            )}
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  {patientCauseInfo?.causeBillDetails[0]?.otherBillDetails
                    ?.length ? (
                    <tr>
                      <td>Other Charges</td>
                      <td>
                        <Table bordered>
                          <thead>
                            <th>service</th>
                            <th>Charges</th>
                            <th>Status</th>
                          </thead>
                          {patientCauseInfo?.causeBillDetails[0]?.otherBillDetails?.map(
                            (item, i) => {
                              return (
                                <tbody>
                                  <tr>
                                    <td>{item?.hospitalService}</td>
                                    <td>{item?.price}</td>
                                    <td>{item?.PaymentMethod}</td>
                                    {/* <td>{item?.Quantity}</td>
                          <td>{item?.price}</td> */}
                                  </tr>
                                </tbody>
                              );
                            }
                          )}
                        </Table>
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  {patientCauseInfo?.causeBillDetails[0]?.SurgeryBillDetails
                    ?.length ? (
                    <tr>
                      <td>Surgery Charges</td>
                      <td>
                        <Table bordered>
                          <thead>
                            <th>Surgery</th>
                            <th>Date</th>
                            <th>Started At</th>
                            <th>Ended At</th>
                            <th>Charges</th>
                            <th>Status</th>
                          </thead>
                          {patientCauseInfo?.causeBillDetails[0]?.SurgeryBillDetails?.map(
                            (item, i) => {
                              return (
                                <tbody>
                                  <tr>
                                    <td>{item?.SurgerName}</td>
                                    <td>{item?.SurgeryDate}</td>
                                    <td>{item?.SurgeryStartTime}</td>
                                    <td>{item?.SurgeryEndTime}</td>
                                    <td>
                                      {patientdetails?.haveInsurance ? (
                                        <>{item?.SurgeryAmountForInsurance}</>
                                      ) : (
                                        <>
                                          {item?.SurgeryAmountForNonInsurance}
                                        </>
                                      )}
                                    </td>
                                    <td>Pay later</td>
                                  </tr>
                                </tbody>
                              );
                            }
                          )}
                        </Table>
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                </tbody>
              </Table>

              {/* Payment details */}
              <div
                style={{ border: "1px solid gray ", marginBottom: "10px" }}
              ></div>
              <div>
                <b>Payment Details</b>
              </div>
              <div
                style={{
                  padding: "20px",
                  marginRight: "30px",
                  marginLeft: "30px",
                }}
              >
                <Table>
                  <tbody>
                    <tr>
                      <td>Total Bill</td>
                      <td>
                        {bedTotal +
                          pharmacyTotalPaid +
                          pharmacyTotalUnPaid +
                          labTotalPaid +
                          labTotalUnPaid +
                          otherBillDetailsPaid +
                          otherBillDetailsUnPaid +
                          surgeryBillDetails}{" "}
                        /-
                      </td>
                    </tr>
                    <tr>
                      <td>Amount Received</td>
                      <td>
                        {pharmacyTotalPaid +
                          labTotalPaid +
                          otherBillDetailsPaid}
                        /-
                      </td>
                    </tr>
                    <tr>
                      <td>Discount</td>
                      <td>0/-</td>
                    </tr>
                    <tr>
                      <td>Payable</td>
                      <td>
                        {bedTotal +
                          pharmacyTotalUnPaid +
                          labTotalUnPaid +
                          otherBillDetailsUnPaid +
                          surgeryBillDetails}
                        /-
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div
                style={{ border: "1px solid gray ", marginBottom: "10px" }}
              ></div>

              {/* Discharge details */}
              {patientCauseInfo?.isDischarged ? (
                <div>
                  <div
                    style={{ border: "1px solid gray ", marginBottom: "10px" }}
                  ></div>
                  <div>
                    <b>Discharge</b>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div
                      style={{
                        padding: "20px",
                        marginRight: "30px",
                        marginLeft: "30px",
                      }}
                    >
                      <Table>
                        <tbody>
                          <tr>
                            <td>Discharge Date : </td>
                            <td>
                              {`${new Date(
                                patientCauseInfo?.dischargeDate
                              ).getDate()}-${
                                new Date(
                                  patientCauseInfo?.dischargeDate
                                ).getMonth() + 1
                              }-${new Date(
                                patientCauseInfo?.dischargeDate
                              ).getFullYear()}`}
                            </td>
                          </tr>
                          <tr>
                            <td>Discharged By : </td>
                            <td>{patientCauseInfo?.dischargedByName}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <div>
                      <img
                        src={`https://softwarelinks.in/PatientREG/${patientCauseInfo?.dischargingAutoritySign}`}
                        alt="sign"
                        style={{ width: "150px" }}
                      />
                      <div style={{ textAlign: "center" }}>
                        Discharged By Signature
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ border: "1px solid gray ", marginBottom: "10px" }}
                  ></div>
                </div>
              ) : (
                <></>
              )}

              <div className="text-center text-dark ">
                <p>
                  Bill Generated By: Janani Hospital, Contact :
                  JananiHospital@gamil.com{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
